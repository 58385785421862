import { graphql } from "gatsby"
import React from "react"
import { Item } from "../components/Item"
import { Layout } from "../components/Layout/"
import { SEO } from "../components/SEO"
export default function Home({ data }) {
  const { items } = data.articles
  return (
    <Layout>
      <SEO title="Home" />
      {items.map(
        ({ node: { title, slug, createdAt, body, featuredImage } }, i) => (
          <Item
            key={i}
            title={title}
            href={slug}
            meta={`Geschreven op: ${createdAt}`}
            content={body.json}
            truncate
          />
        )
      )}
    </Layout>
  )
}

export const query = graphql`
  query {
    articles: allContentfulArticle(limit: 5) {
      items: edges {
        node {
          createdAt(formatString: "DD-MM-YYYY")
          slug
          title
          body {
            json
          }
          featuredImage {
            title
            fixed(width: 320) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
    }
  }
`
