import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Body } from "../Body"
import styles from "./Item.module.css"

export const Item = ({ title, href, meta, content, truncate }) => {
  const renderHeading = () => {
    if (href) {
      return (
        <h1 className={styles.item__title}>
          <Link to={href}>{title}</Link>
        </h1>
      )
    }
    return <h1 className={styles.item__title}>{title}</h1>
  }
  return (
    <div className={styles.item}>
      {renderHeading()}
      {meta && <p className={styles.item__meta}>{meta}</p>}
      <Body
        className={styles.item__excerpt}
        data={content}
        truncate={truncate}
      />
    </div>
  )
}

Item.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
  created: PropTypes.string,
  content: PropTypes.string,
  truncate: PropTypes.bool,
}
